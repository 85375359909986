body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.banner-text, .nav{
  background-color: rgb(161, 160, 160);
  opacity: 0.9;
}
.banner-text{
  border-radius: 15vh;
  padding-bottom: 1vh;
  padding-top: 2vh;
}
#portfolio{
  display: flex;
  justify-content: space-evenly;
}
#a-and-a-designs, #welp, #partyTracker{
  height: 50vh;
}
#portfolio-wrapper{
  display: flex;
  justify-content: space-evenly;
}
#item{
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item-wrap{
  width: 30vw;
}
.skills{
  display: flex;
  justify-content: space-between;
}
.skills-titles{
  font-size: 4vh;
  padding-bottom: 3vh;
}
.address{
  width: 50vw;
}
.work{
  margin-bottom: 50px;
}
@media (max-width: 500px){
    #portfolio-wrapper{
        display: flex;
        flex-direction: column;
    }
    .item-wrap{
        width: 100%;
    }
    #item{
        width: 100%;
    }
    #welp{
        width: 100%;
    }
}
