.banner-text, .nav{
  background-color: rgb(161, 160, 160);
  opacity: 0.9;
}
.banner-text{
  border-radius: 15vh;
  padding-bottom: 1vh;
  padding-top: 2vh;
}
#portfolio{
  display: flex;
  justify-content: space-evenly;
}
#a-and-a-designs, #welp, #partyTracker{
  height: 50vh;
}
#portfolio-wrapper{
  display: flex;
  justify-content: space-evenly;
}
#item{
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item-wrap{
  width: 30vw;
}
.skills{
  display: flex;
  justify-content: space-between;
}
.skills-titles{
  font-size: 4vh;
  padding-bottom: 3vh;
}
.address{
  width: 50vw;
}
.work{
  margin-bottom: 50px;
}